
import React from 'react'
import { List, Footer, Ellipsis, Tag, FloatingBubble, NavBar, Swiper, Image, Tabs, Result } from 'antd-mobile'
import { GiftOutline } from 'antd-mobile-icons'
import './index.scss';



import { createFromIconfontCN } from '@ant-design/icons';
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4335219_nhipwl2o8u8.js',
});

const getDecode = (str) => {
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}





const S1menu = () => {
  const token = JSON.parse(localStorage.getItem('cx315_token'))
  const html = getDecode(token.djson);
  console.log(html)

  return <>



    <NavBar back={null} style={{
      '--height': '40px',
      background: '#02af6f',
      color: '#FFF',
      // '--border-bottom': '1px #eee solid',
    }}>
      {token.ttm}
    </NavBar>
    <Swiper autoplay>
      {token.ys_bn[0].bsh.map((k) => <Swiper.Item key={`topimg${k.xh}`}><Image src={k.baimg} /></Swiper.Item>)}


    </Swiper>


    <Tabs style={{ '--active-title-color': '#02af6f', '--active-line-color': '#02af6f', '--content-padding': '0px' }}>
      <Tabs.Tab title='追溯信息'
        // style={{'--active-title-color':'#02af6f','--active-line-color':'#02af6f'}}
        key='m1'>


        <List style={{ '--font-size': '1em', '--padding-left': '0px' }}>
          <List.Item extra={token.resq.dbcode} key='a1'> 单元识别码</List.Item>


          <List.Item description={<Ellipsis
            direction='end'
            content={`http://ddspp.cn/S/${token.resq.dbcode}`}
            expandText='展开'
            collapseText='收起'
          />} key='a2'>
            追溯网址
          </List.Item>
          <List.Item extra={token.dbno} key='a3'> 数据批次</List.Item>
          <List.Item extra={token.dblx} key='a4'> 数据类型</List.Item>

          <List.Item extra={<Tag
            color='success'
            fill='outline'
            style={{ '--background-color': '#c8f7c5', '--border-radius': '3px' }}
            key='a5'
          >
            {token.yz_cs}
          </Tag>}>扫码次数</List.Item>






        </List>

        <div style={{
          width: '100%',
          height: '39px',
          background: '#02af6f',
          backgroundImage: 'linear-gradient(to right, #02af6f, #fff)',
          color: '#FFF',
          lineHeight: '39px',

        }}>

          产品信息
        </div>


        <List style={{ '--font-size': '1em', '--padding-left': '0px' }}>
          {token.pmsgjson.map((ea) => <List.Item extra={ea.mmsg} key={ea.mmsg}> {ea.title}</List.Item>)}




        </List>


        <div style={{
          width: '100%',
          height: '39px',
          background: '#02af6f',
          backgroundImage: 'linear-gradient(to right, #02af6f, #fff)',
          color: '#FFF',
          lineHeight: '39px',

        }}>

          企业信息
        </div>

        <List style={{ '--font-size': '1em', '--padding-left': '0px' }}>
          {token.qmsgjson.map((e) => <List.Item extra={e.mmsg} key={e.mmsg}> {e.title}</List.Item>)}




        </List>



      </Tabs.Tab>
      <Tabs.Tab title='产品详情' key='m2'>
        {true && <div className='about' dangerouslySetInnerHTML={{ __html: html }}></div>}
      </Tabs.Tab>

      <Tabs.Tab title='认证信息' key='m3'>

        {token.rzzt === 'Y' && <Result
          icon={<IconFont type="icon-yiyanzheng" style={{
            fontSize: '5em',
          }} />}
          status='success'
          title='已认证主体'
          description='企业认证为核准工商登记信息及对公账户均正确，且对公收款金额验证通过的结果。'
        />}

        {token.rzzt === 'N' && <Result
          icon={<IconFont type="icon-weiyanzheng" style={{
            fontSize: '5em',
          }} />}
          status='warning'
          title='未认证主体'
          description='企业认证到期、过期或者从未进行过企业认证的主体。'
        />}




      </Tabs.Tab>

    </Tabs>



    {false && <FloatingBubble axis='x' magnetic='x' style={{
      '--initial-position-bottom': '188px',
      '--initial-position-right': '29px',
      '--border-radius': '5px',
      '--edge-distance': '24px',
      '--background': '#fafafa',
    }}
    >
      <GiftOutline fontSize={32} color='red' />
    </FloatingBubble>
    }









    <Footer
      label='以下无内容'
      content='@ 技术支持 胜聪网 www.ddspp.com'
      className='footer'

    ></Footer>



  </>
}

export default S1menu;