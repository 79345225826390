import React from 'react';
import { NavBar,Result } from 'antd-mobile'

import { createFromIconfontCN } from '@ant-design/icons';
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4335219_nhipwl2o8u8.js',
});

const Honors = () => {
  const token = JSON.parse(localStorage.getItem('cx315_token'))
  


  ///请求 返回json  写入

  return <>
<NavBar back={null} style={{
      '--height': '40px',
      background: '#02af6f',
      color: '#FFF',
      // '--border-bottom': '1px #eee solid',
    }}>
      {token.ttm}
    </NavBar>

{ token.rzzt==='Y' &&  <Result
          icon={<IconFont type="icon-yiyanzheng" style={{
            fontSize:'5em',
          }}/>}
          status='success'
          title='已认证主体'
          description='企业认证为核准工商登记信息及对公账户均正确，且对公收款金额验证通过的结果。'
        /> }
   
   { token.rzzt==='N' &&  <Result
          icon={<IconFont type="icon-weiyanzheng" style={{
            fontSize:'5em',
          }}/>}
          status='warning'
          title='未认证主体'
          description='企业认证到期、过期或者从未进行过企业认证的主体。'
        />  }



    

  </>
}

export default Honors;