import React from 'react';

import { Divider, NavBar, Space, NoticeBar,FloatingBubble} from 'antd-mobile'



import { SearchOutline, FillinOutline,GiftOutline,EyeOutline,EyeFill } from 'antd-mobile-icons'
import { createFromIconfontCN } from '@ant-design/icons';
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4335219_nhipwl2o8u8.js',
});








const M1 = () => {

  const right = (
    <div style={{ fontSize: 24 }}>
      <Space style={{ '--gap': '16px' }}>
        <SearchOutline />

      </Space>
    </div>
  )


  ///请求 返回json  写入

  return <>

    <NavBar right={right} back={null} style={{
      '--height': '32px',
      background: '#02af6f',
      color: '#FFF',
      // '--border-bottom': '1px #eee solid',
    }}>
      这是一条很长很长很长很长很长很长的标题
    </NavBar>


    <IconFont
      type="icon-a-013_ditudingwei"
      style={{
        color: '#02af6f',
        fontSize:'5em',
      }}
    />
    <IconFont
      type="icon-a-013_gongsidizhi"
      style={{
        fontSize:'5em',
      }}
    />

<IconFont
      type="icon-a-013_gongsidizhi"
      style={{
        fontSize:'9em',
        color:'gray'
      }}

    />





    
    <div style={{
        width: '400px',
        height:'39px',
        background: '#02af6f',
        'background-image': 'linear-gradient(to right, #02af6f, #fff)',
        color: '#FFF',
        lineHeight:'39px',paddingLeft:'7px'
        // '--border-bottom': '1px #eee solid',
      }}>

        这里是栏目
      </div>
    <NoticeBar content='ssssssssssddddddddddaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'
      color='error'
      closeable />
    <Divider
      style={{
        color: '#02af6f',
        borderColor: '#02af6f',
        borderStyle: 'dashed',
      }}
    >
      胜聪网  ShengCongWang
    </Divider>

    <Divider contentPosition='left' style={{
      color: '#02af6f',
      borderColor: '#02af6f',
    }}><FillinOutline />左侧内容</Divider>


<FloatingBubble
        axis='xy'
        magnetic='x'
        style={{
          '--background':'none',
          '--initial-position-bottom': '24px',
          '--initial-position-right': '24px',
          '--edge-distance': '24px',
        }}
      >
       
   
        <EyeOutline fontSize={32} color='#1677ff'/>
      </FloatingBubble>
      <FloatingBubble
        axis='xy'
        magnetic='x'
        style={{
          '--background':'none',
          '--initial-position-bottom': '84px',
          '--initial-position-right': '24px',
          '--edge-distance': '24px',
        }}
      >
       
        <GiftOutline fontSize={32} color='red'/>
    
      </FloatingBubble>

      <FloatingBubble
        axis='xy'
        magnetic='x'
        style={{
          '--background':'none',
          '--initial-position-bottom': '124px',
          '--initial-position-right': '24px',
          '--edge-distance': '24px',
        }}
      >
       
        <EyeFill fontSize={32} color='red'/>
    
      </FloatingBubble>
     


  </>
}

export default M1;