import React from 'react'
import { ErrorBlock} from 'antd-mobile'
const E404=()=>{

    return <><ErrorBlock status='empty' 
    fullPage={true}
    description='出现错误了'
    /></>
}

export default E404;