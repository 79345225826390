import React from 'react'
import { TabBar } from 'antd-mobile'
import {

    FillinOutline,
} from 'antd-mobile-icons'
import { IdcardOutlined } from '@ant-design/icons';
import {
    Outlet,
    useLocation,
    useNavigate
} from 'react-router-dom'
import './index.scss';

const G1 = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location


    const setRouteActive = (value) => {
        navigate('/GD' + value)

    }

    const tabs = [
        {
            key: '/index',
            title: '首页',
            icon: <FillinOutline />,
        },

        {
            key: '/honors',
            title: '资质荣誉',
            icon: <IdcardOutlined />,
        },

    ]

    return (
        <>
            <Outlet></Outlet>


            { false && <TabBar activeKey={pathname} onChange={value => setRouteActive(value)} className='foot'>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>}
        </>





    )
}

export default G1;