import React from 'react';
import { NavBar, Steps, List, Button } from 'antd-mobile'

import { FlagOutline } from 'antd-mobile-icons'
const { Step } = Steps

const Flow = () => {
  const token = JSON.parse(localStorage.getItem('cx315_token'))



  ///请求 返回json  写入

  return <>
    <NavBar back={null} style={{
      '--height': '40px',
      background: '#02af6f',
      color: '#FFF',
      // '--border-bottom': '1px #eee solid',
    }}>
      {token.ttm}
    </NavBar>
    <Steps direction='vertical'>
      <Step
        title='填写机构信息'
        status='finish'
        description='完成时间：2020-12-01 12:30'
      />
      <Step
        title='签约机构'
        status='finish'
        description='完成时间：2020-12-01 12:30'
      />
      <Step
        title={<><FlagOutline /> <Button block color='primary' size='large'>
          Block Button
        </Button>关联服务区关联服务区关联服务区关联服务区关联服务区关联服务区关联服务区关联服务区关联服务区</>}
        status='finish'
        description='完成时间：2020-12-01 12:30'
      />


      <Step
        title='签约机构'
        status='finish'
        description='完成时间：2020-12-01 12:30'
      />
      <Step
        title='签约机构'
        status='finish'
        description='完成时间：2020-12-01 12:30'
      />

    </Steps>




    <List>
      <List.Item
        prefix={<><FlagOutline />经销商</>}
        description='Deserunt dolor ea eaque eos'
      >
        Novalee Spicer
      </List.Item>
    </List>



  </>
}

export default Flow;